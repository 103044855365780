<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FED159;"
      d="M512,198.884c0,34.394-13.415,66.713-37.768,91c-9.84,9.814-16.093,23.657-17.605,38.986
	c-2.383,24.085-13.375,46.551-30.967,63.273c-9.064,8.609-19.48,15.356-30.739,20.015c-12.156,5.047-25.304,7.685-38.866,7.685
	c-0.857,0-1.7-0.013-2.557-0.027l-24.085-0.589l1.165-48.184l24.099,0.589c14.191,0.348,27.62-4.78,37.782-14.432
	c0.857-0.817,1.674-1.647,2.463-2.517c7.765-8.408,12.625-19.145,13.75-30.552c2.611-26.415,13.803-50.701,31.529-68.374
	c15.222-15.182,23.603-35.372,23.603-56.873c0-21.796-8.595-42.2-24.193-57.449c-12.371-12.076-27.901-19.694-44.69-22.077
	c-4.378-0.616-8.85-0.884-13.361-0.777c-7.002,0.161-13.937,1.245-20.618,3.227l-23.095,6.855L324.123,82.46l23.108-6.855
	c10.764-3.2,21.943-4.954,33.23-5.208c4.86-0.107,9.693,0.04,14.459,0.469c29.467,2.571,56.9,15.115,78.375,36.095
	C498.25,131.354,512,163.994,512,198.884z"
    />
    <g>
      <path
        style="fill:#F6C454;"
        d="M394.92,70.866v48.492c-4.378-0.616-8.85-0.884-13.361-0.777
		c-7.002,0.161-13.937,1.245-20.618,3.227l-23.095,6.855L324.123,82.46l23.108-6.855c10.764-3.2,21.943-4.954,33.23-5.208
		C385.321,70.29,390.154,70.437,394.92,70.866z"
      />
      <path
        style="fill:#F6C454;"
        d="M392.457,357.199c0.857-0.817,1.674-1.647,2.463-2.517v57.476
		c-12.156,5.047-25.304,7.685-38.866,7.685c-0.857,0-1.7-0.013-2.557-0.027l-24.085-0.589l1.165-48.184l24.099,0.589
		C368.867,371.98,382.295,366.852,392.457,357.199z"
      />
    </g>
    <path
      style="fill:#FED159;"
      d="M360.786,28.59H0v385.887c0,43.615,35.357,78.972,78.972,78.972h202.842
	c43.615,0,78.972-35.357,78.972-78.972L360.786,28.59L360.786,28.59z"
    />
    <path
      style="fill:#F6C454;"
      d="M62.253,414.477V28.591H0v385.887c0,43.615,35.357,78.972,78.972,78.972h62.253
	C97.609,493.449,62.253,458.092,62.253,414.477z"
    />
    <path
      style="fill:#FDD782;"
      d="M278.609,28.591v385.887c0,43.615-35.357,78.972-78.972,78.972h39.171
	c43.615,0,78.972-35.357,78.972-78.972V28.591H278.609z"
    />
    <polygon
      style="fill:#82DCC7;"
      points="202.868,145.256 127.563,111.786 52.257,145.256 52.257,306.575 202.868,306.575 "
    />
    <g>
      <polygon
        style="fill:#707070;"
        points="120.044,197.398 135.08,197.398 159.143,197.398 159.143,182.362 135.08,182.362 
		135.08,165.686 120.044,165.686 120.044,182.362 95.983,182.362 95.983,226.58 144.107,226.58 144.107,240.726 135.08,240.726 
		120.044,240.726 95.983,240.726 95.983,255.761 120.044,255.761 120.044,272.437 135.08,272.437 135.08,255.761 159.143,255.761 
		159.143,211.544 111.018,211.544 111.018,197.398 	"
      />
      <path
        style="fill:#707070;"
        d="M127.563,141.91c-5.547,0-10.041-4.496-10.041-10.041V28.591c0-5.545,4.494-10.041,10.041-10.041
		s10.041,4.496,10.041,10.041v103.278C137.604,137.415,133.108,141.91,127.563,141.91z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
